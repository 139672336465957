<template>

    <div class="phone">
        <div class="row phone-content">
            <div class="col-12 phone-content-card">
                <div class="row justify-content-center">
                    <div class="col-auto ">
                        <b-overlay :show="loading" rounded="sm" class="card ">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div class="text-center my-4">
                                    <img src="@/assets/images/tendencys.svg" class="img-80" alt="">
                                    <h5 class="f-w-600" v-text="$t('messages.verifyPhone')"></h5>
                                    <p v-if="user.phoneCode">
                                        <strong>
                                            <span v-text=" '( +' + user.phoneCode + ' ) ' "></span>
                                            <span v-text=" user.phone "></span>
                                        </strong>
                                    </p>
                                    <p v-text="$t('messages.verificationPhoneMessage')"></p>
                                </div>
                                <v-observer ref="formCode" tag="form" class="mb-4" v-slot="{valid}"
                                    @submit.prevent="fnValidateCode()">
                                    <div class="row justify-content-center">
                                        <div class="col-auto">
                                            <SignupCode :amount="7" @onCompleted="code => activationCode = code"
                                                v-bind:valid="valid" />
                                            <v-validation rules="required|min:7|max:7"
                                                v-slot="{ validated,passed,errors }" :name="$t('general.code')">
                                                <b-form-group label-for="input-formatter">
                                                    <b-input-group>
                                                        <b-form-input v-model="activationCode" hidden type="text"
                                                            :state=" (validated) ? passed : null "
                                                            @input="signupErrors.code = false" id="activationCode">
                                                        </b-form-input>
                                                    </b-input-group>
                                                    <b-form-invalid-feedback :state="(validated) ? passed : null"
                                                        v-text="errors[0]">
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </v-validation>
                                        </div>
                                    </div>
                                </v-observer>
                                <div class="row align-items-center justify-content-center">

                                    <div class="col-12" >
                                        <div class="">
                                            <p class="text-center" :class="  verifiacitonTime > 0 ? 'text-muted' : null ">
                                                <strong class="f-18 animated fadeIn" v-text=" verifiacitonTime" v-if="verifiacitonTime > 0"></strong>
                                                <span class="animated fadeIn" v-text="$t('messages.verifyPhoneQuestion')" v-else></span>
                                                <br>
                                                <button class="btn btn-link p-0" :disabled="verifiacitonTime > 0" @click=" $refs['SignupCodeModal'].showModal = true "
                                                    v-text=" $t('messages.sendAgain') + ' / ' + $t('general.change') ">
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <b-alert :show="signupErrors.code" variant="danger">
                                    <p class="m-0" v-text="$t('messages.invalidCode')"></p>
                                </b-alert>
                                <b-button variant="success" class="w-100" @click="fnValidateCode()" :disabled="loading">
                                    <div v-if="loading">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </div>
                                    <div v-else>
                                        <span v-text="$t('messages.valdiateCode')"></span>
                                    </div>
                                </b-button>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>

        <SignupCodeModal @sendCode="fnApiSendCode" @changePhone="fnChangePhone" ref="SignupCodeModal" />
    </div>

</template>

<script>
    import SignupCode from "./components/signup-code.vue";
    import SignupCodeModal from "./components/signup-verification-code-modal.vue";
    export default {
        components: {
            SignupCode,
            SignupCodeModal
        },
        data() {
            return {
                loading: false,
                verifiacitonTime: 0,
                activationCode: null,
                timeInterval: null,
                step: 1,
                signupErrors: {
                    email: false,
                    code: false,
                },
                user: {
                    phone: null,
                    phoneCode: null,
                    email: null,
                }
            }
        },
        methods: {
            async fnValidateCode() {
                if (await this.$refs.formCode.validate()) {
                    this.loading = true;
                    await axiosAccount.post("validatePhoneCode", {
                        phone: this.user.phone,
                        code: this.user.phoneCode,
                        activation_code: this.activationCode,
                        site_id: this.user.siteId,
                        type: 'SMS',
                    }).then(async response => {
                        window.location.replace(localStorage.getItem('accountUrl'));
                    }).catch(error => {
                        if (error.response.status == 401) {
                            this.signupErrors.code = true;
                        }
                        if (error.response.status == 400) {
                            this.signupErrors.code = true;
                        }
                    });
                    setTimeout(async () => {
                        this.loading = false;
                    }, 800);
                }
            },

            fnChangePhone([codeType, newPhone, newPhoneCode]) {
                this.user.phone = newPhone;
                this.user.phoneCode = newPhoneCode;
                this.fnApiSendCode(codeType, newPhone, newPhoneCode);
            },

            async fnApiSendCode(codeType, newPhone, newPhoneCode) {
                this.loading = true;
                let language = 'en-US'
                switch (this.$store.state.language) {
                    case 'en':
                        language = 'en-US';
                        break;
                    case 'es':
                        language = 'es-ES';
                        break;
                }
                let payload = {
                    site_id: this.$route.query.site_id || '',
                    email: this.user.email,
                    language,
                    type: codeType || 'Sms',
                }
                if (newPhone) {
                    payload.phone = newPhone;
                }
                if (newPhoneCode) {
                    payload.code = newPhoneCode;
                }
                await axiosAccount.post("sendPhoneCode", payload).then(async response => {
                    this.user.phone = response.data.phone;
                    this.user.phoneCode = response.data.code;
                    this.fnSetTimeCode(30);
                }).catch(error => {});
                this.loading = false;
            },

            fnSetTimeCode(time) {
                this.verifiacitonTime = time;
                this.timeInterval = setInterval(() => {
                    if (this.verifiacitonTime > 0) {
                        this.verifiacitonTime--;
                    } else {
                        clearInterval(this.timeInterval);
                    }
                }, 1000);
            },

            async fnApiCheckEmail() {
                await axiosAccount.get("../accounts/getSessionEmail").then(async response => {
                    this.user.email = response.data;
                    this.fnApiSendCode();
                }).catch(error => {});
            },
        },
        mounted() {
            this.fnApiCheckEmail();
            if (this.$route.query.site_id) {
                this.user.siteId = this.$route.query.site_id;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .phone {
        background-color: black;
        padding-top: 25vh;
        .phone-content {
            min-height: 75vh;
            background-color: white;
            .phone-content-card {
                .card {
                    min-height: 500px;
                    width: 600px;
                }
                transform: translateY(-10vh);
                @media only screen and (max-width: 767px) {
                    .card {
                        min-height: 500px;
                        width: auto;
                    }

                    transform: translateY(-15vh);
                }
            }
            @media only screen and (max-width: 767px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
</style>